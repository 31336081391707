<template>
  <div class="applyProductCombo-container">
    <div class="comboList">
      <div class="header2 merchant_header">
        <van-nav-bar
          title="商家信息变更"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        ></van-nav-bar>
      </div>
      <van-form
        class="merchant_cs1"
        validate-first
        style="margin-bottom: 100px"
      >
        <van-cell-group>
          <van-cell title="供货商名称:">
            <template #title> 供货商名称: </template>
            <van-field v-model="query.merchantName" readonly maxlength="30" />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="所属省市:">
            <template #title> 所属省市: </template>
            <van-field v-model="query.belongsCity" readonly maxlength="30" />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="所属产品专员:">
            <template #title> 所属产品专员: </template>
            <van-field v-model="query.managerName" readonly maxlength="30" />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="社会信用代码:">
            <template #title>
              社会信用代码: <span style="color: red; font-size: 16px">*</span>
            </template>
            <van-field
              v-model="query.cods"
              maxlength="30"
              placeholder="上传营业执照识别成功后自动填入"
            />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="商家地址:">
            <template #title>
              商家地址: <span style="color: red; font-size: 16px">*</span>
            </template>
            <van-field
              v-model="query.merchantAddress"
              maxlength="30"
              placeholder="上传营业执照识别成功后自动填入"
            />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="法人姓名:">
            <template #title>
              法人姓名: <span style="color: red; font-size: 16px">*</span>
            </template>
            <van-field
              v-model="query.legalPersonName"
              maxlength="30"
              placeholder="上传营业执照识别成功后自动填入"
            />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="法人手机号:">
            <template #title>
              法人手机号: <span style="color: red; font-size: 16px">*</span>
            </template>
            <van-field
              v-model="query.legalPersonPhone"
              maxlength="30"
              placeholder="请输入法人手机号"
            />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="联系人姓名:">
            <template #title>
              联系人姓名: <span style="color: red; font-size: 16px">*</span>
            </template>
            <van-field
              v-model="query.contacts"
              maxlength="30"
              placeholder="请输入联系人姓名"
            />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="网店网址:">
            <template #title> 网店网址: </template>
            <van-field
              v-model="query.onlineshopAddress"
              maxlength="30"
              placeholder="请输入网店网址"
            />
          </van-cell>
        </van-cell-group>

        <van-cell title="营业执照:" class="titleImg_cs">
          <template #title>
            营业执照: <span style="color: red; font-size: 16px">*</span>
          </template>
          <el-upload
            style="display: inline-block; padding-left: 18px"
            :limit="max"
            :action="
              $store.state.imgUpdateUrl +
              '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
            "
            list-type="picture-card"
            :file-list="query.businessLicensePics"
            :on-success="
              (response) => {
                return productRowUpdateFlie(response);
              }
            "
            :on-exceed="fnmax"
            :on-remove="
              (file, fileList) => {
                return productHandleRemove(file, fileList);
              }
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </van-cell>
        <van-cell title="生产许可证:" class="titleImg_cs">
          <template #title>
            生产许可证: <span style="color: red; font-size: 16px">*</span>
          </template>
          <el-upload
            style="display: inline-block; padding-left: 18px"
            :limit="max"
            :action="
              $store.state.imgUpdateUrl +
              '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
            "
            list-type="picture-card"
            :file-list="query.manufacturingLicenses"
            :on-success="
              (response) => {
                return productRowUpdateFlie1(response);
              }
            "
            :on-exceed="fnmax"
            :on-remove="
              (file, fileList) => {
                return productHandleRemove1(file, fileList);
              }
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </van-cell>
        <van-cell title="其它证照:" class="titleImg_cs">
          <template #title> 其它证照: </template>
          <el-upload
            style="display: inline-block; padding-left: 18px"
            :limit="max"
            :action="
              $store.state.imgUpdateUrl +
              '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
            "
            list-type="picture-card"
            :file-list="query.otherLicenses"
            :on-success="
              (response) => {
                return productRowUpdateFlie2(response);
              }
            "
            :on-exceed="fnmax"
            :on-remove="
              (file, fileList) => {
                return productHandleRemove2(file, fileList);
              }
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </van-cell>
      </van-form>
      <van-tabbar>
        <div style="width: 100%; text-align: center">
          <van-button
            style="
              background-color: #8585ad;
              color: white;
              width: 20%;
              border-radius: 6px;
            "
            @click="onClickLeft"
            >取消</van-button
          >
          <van-button
            style="
              background-color: #00ccff;
              color: white;
              width: 20%;
              border-radius: 6px;
            "
            v-if="disabledBtn"
            @click="confirm"
            >确定</van-button
          >
          <van-button
            style="
              background-color: #00ccff;
              color: white;
              width: 20%;
              border-radius: 6px;
            "
            v-else
            disabled
            >确定</van-button
          >
        </div>
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import { Empty, Notify } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      disabledBtn: true,
      query: {
        merchantName: "",
        belongsCity: "",
        managerName: "",
        cods: "",
        merchantAddress: "",
        legalPersonName: "",
        legalPersonPhone: "",
        contacts: "",
        phone: "",
        onlineshopAddress: "",
        businessLicensePics: [],
        manufacturingLicenses: [],
        otherLicenses: [],
      },
      max: 1,
    };
  },
  created() {
    Toast.loading({
      message: "加载中...",
      overlay: true,
      duration: 200,
      forbidClick: true,
    });
    setTimeout(() => {
      this.getmerchantList();
    }, 100);
  },
  methods: {
    // 最多上传数量
    fnmax(el) {
      Notify({ type: "danger", message: "最多允许上传" + this.max + "张" });
    },
    // 上传营业执照
    productRowUpdateFlie(response) {
      this.$http
        .ajax(
          "/ffkj-main/merchant/getBusinessLicense",
          {
            imgUlr: response,
          },
          "get",
          {}
        )
        .then((res) => {
          if (res.msg == "营业执照识别成功") {
            Notify({ type: "success", message: res.msg });
            (this.query.merchantAddress = res.body.businessLicenseVo.address),
              (this.query.merchantName =
                res.body.businessLicenseVo.companyName),
              (this.query.cods = res.body.businessLicenseVo.code),
              (this.query.legalPersonName =
                res.body.businessLicenseVo.legalPerson),
              this.query.businessLicensePics.push({ url: response });
          } else {
            Notify({ type: "danger", message: res.msg });
            this.query.businessLicensePics = [];
          }
        });
    },
    // 删除营业执照
    productHandleRemove(file, fileList) {
      this.query.businessLicensePics = fileList;
    },
    // 上传生产许可证
    productRowUpdateFlie1(response) {
      this.query.manufacturingLicenses.push({ url: response });
    },
    // 删除生产许可证
    productHandleRemove1(file, fileList) {
      this.query.manufacturingLicenses = fileList;
    },
    // 上传其它证照
    productRowUpdateFlie2(response) {
      this.query.otherLicenses.push({ url: response });
    },
    // 删除其它证照
    productHandleRemove2(file, fileList) {
      this.query.otherLicenses = fileList;
    },
    // 返回事件
    onClickLeft() {
      this.$router.push({
        path: "/merchantInfo",
        query: {},
      });
    },
    // 提交审核
    confirm() {
      if (!this.query.cods || this.query.cods.trim().length == 0) {
        Notify({ type: "danger", message: "社会信用代码不能为空!!!" });
        return fasle;
      }
      if (
        !this.query.merchantAddress ||
        this.query.merchantAddress.trim().length == 0
      ) {
        Notify({ type: "danger", message: "商家地址不能为空!!!" });
        return fasle;
      }
      if (
        !this.query.legalPersonName ||
        this.query.legalPersonName.trim().length == 0
      ) {
        Notify({ type: "danger", message: "法人姓名不能为空!!!" });
        return fasle;
      }
      if (
        !this.query.legalPersonPhone ||
        this.query.legalPersonPhone.trim().length == 0
      ) {
        Notify({ type: "danger", message: "法人手机号不能为空!!!" });
        return fasle;
      }
      if (!this.query.contacts || this.query.contacts.trim().length == 0) {
        Notify({ type: "danger", message: "联系人姓名不能为空!!!" });
        return fasle;
      }
      if (
        !this.query.legalPersonPhone ||
        this.query.legalPersonPhone.trim().length == 0
      ) {
        Notify({ type: "danger", message: "法人手机号不能为空!!!" });
        return fasle;
      }
      if (this.query.businessLicensePics.length <= 0) {
        Notify({ type: "danger", message: "请上传营业执照!!!" });
        return false;
      }
      if (this.query.manufacturingLicenses.length <= 0) {
        Notify({ type: "danger", message: "请上传生产许可证!!!" });
        return false;
      }
      this.disabledBtn = false;
      let form = {
        businessLicensePics: [],
        manufacturingLicenses: [],
        otherLicenses: [],
      };
      form.businessLicensePics.push(this.query.businessLicensePics[0].url);
      form.manufacturingLicenses.push(this.query.manufacturingLicenses[0].url);
      if (this.query.otherLicenses.length > 0) {
        form.otherLicenses.push(this.query.otherLicenses[0].url);
      }
      this.$http
        .ajax(
          "/ffkj-main/merchant/baseInfo/edit/" +
            localStorage.getItem("merchantId"),
          {
            managerName: this.query.managerName,
            phone: localStorage.getItem("ms_phone"),
            cods: this.query.cods,
            merchantAddress: this.query.merchantAddress,
            legalPersonName: this.query.legalPersonName,
            legalPersonPhone: this.query.legalPersonPhone,
            contacts: this.query.contacts,
            onlineshopAddress: this.query.onlineshopAddress,
            businessLicensePics: form.businessLicensePics,
            manufacturingLicenses: form.manufacturingLicenses,
            otherLicenses: form.otherLicenses,
          },
          "put",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            Notify({ type: "success", message: "操作成功" });
            setTimeout(() => {
              this.onClickLeft();
            }, 300);
          } else {
            this.disabledBtn = true;
            Notify({ type: "danger", message: res.message });
          }
          var timer = setTimeout(
            function () {
              this.loading = false;
              window.clearTimeout(timer);
            }.bind(this),
            300
          );
        });
    },
    // 商家信息回显
    getmerchantList() {
      this.$http
        .ajax(
          "/ffkj-main/merchant/baseInfo/" + localStorage.getItem("merchantId"),
          {
            pageEnum: "EDIT_PAGE",
          },
          "get",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            if (res.data != undefined) {
                (this.query.merchantName = res.data.merchantName),
                (this.query.belongsCity = res.data.belongsCity),
                (this.query.managerName = res.data.managerName),
                (this.query.cods = res.data.cods),
                (this.query.merchantAddress = res.data.merchantAddress),
                (this.query.legalPersonName = res.data.legalPersonName),
                (this.query.legalPersonPhone = res.data.legalPersonPhone),
                (this.query.contacts = res.data.contacts),
                (this.query.phone = res.data.phone),
                (this.query.onlineshopAddress = res.data.onlineshopAddress);
                if(res.data.businessLicensePics){
                  for (let i = 0; i < res.data.businessLicensePics.length; i++) {
                    this.query.businessLicensePics.push({
                      url: res.data.businessLicensePics[i].url,
                    });
                  }
                }
                if(res.data.manufacturingLicenses){
                  for (let i = 0; i < res.data.manufacturingLicenses.length; i++) {
                    this.query.manufacturingLicenses.push({
                      url: res.data.manufacturingLicenses[i].url,
                    });
                  }
                }
                if(res.data.otherLicenses){
                  for (let i = 0; i < res.data.otherLicenses.length; i++) {
                    this.query.otherLicenses.push({
                      url: res.data.otherLicenses[i].url,
                    });
                  }
                }
            } else {
              this.getmerchantList1();
            }
          } else {
            Notify({ type: "danger", message: res.message });
          }
          var timer = setTimeout(
            function () {
              window.clearTimeout(timer);
            }.bind(this),
            300
          );
        });
    },
    // 商家信息回显1
    getmerchantList1() {
      this.$http
        .ajax(
          "/ffkj-main/merchant/baseInfo/" + localStorage.getItem("merchantId"),
          {
            pageEnum: "QUERY_PAGE",
          },
          "get",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            (this.query.merchantName = res.data.merchantName),
              (this.query.belongsCity = res.data.belongsCity),
              (this.query.managerName = res.data.managerName),
              (this.query.cods = res.data.cods),
              (this.query.merchantAddress = res.data.merchantAddress),
              (this.query.legalPersonName = res.data.legalPersonName),
              (this.query.legalPersonPhone = res.data.legalPersonPhone),
              (this.query.contacts = res.data.contacts),
              (this.query.phone = res.data.phone),
              (this.query.onlineshopAddress = res.data.onlineshopAddress);
              if(res.data.businessLicensePics){
                for (let i = 0; i < res.data.businessLicensePics.length; i++) {
                  this.query.businessLicensePics.push({
                    url: res.data.businessLicensePics[i].url,
                  });
                }
              }
              if(res.data.manufacturingLicenses){
                for (let i = 0; i < res.data.manufacturingLicenses.length; i++) {
                  this.query.manufacturingLicenses.push({
                    url: res.data.manufacturingLicenses[i].url,
                  });
                }
              }
              if(res.data.otherLicenses){
                for (let i = 0; i < res.data.otherLicenses.length; i++) {
                  this.query.otherLicenses.push({
                    url: res.data.otherLicenses[i].url,
                  });
                }
              }
          } else {
            Notify({ type: "danger", message: res.message });
          }
          var timer = setTimeout(
            function () {
              window.clearTimeout(timer);
            }.bind(this),
            300
          );
        });
    },
  },
};
</script>

<style scoped></style>
